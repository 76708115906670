*{
  margin: 5px;
  
}

.container > h2{
  display: flex;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
 justify-content: center;  
  color: #12A9E1;
  border: 1px solid black;
 
  
}
.top-row {
  display: flex;
 
}
.header{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
 
}

.logo{
  display: flex;
  margin-left:  40px;
  
}

.tag {
  display: flex;
  margin-left: 10%; /* Use a percentage or other relative unit */
  justify-content: center; /* Center vertically */
  color: #12A9E1;
}

/* Responsive styling using media queries */
@media screen and (max-width: 768px) {
  .tag {
    margin-left: 5%;
    align-items: center; /* Center horizontally */
  

  }
}

@media screen and (max-width: 480px) {
  .tag {
    margin-left: 2%;
  }
}

.tag2 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  
}

.tag h2 {
  padding: 15px;
  font-family: 'Times New Roman', Times, serif;
  color: #12A9E1;
}

.header__ribbon {
  display: flex;
  background-color: #c3f4d9;
  border-radius: 5px;
  height: 40px;
  /* justify-content: space-between; */
  justify-content: space-around;
}

.header__ribbon > a {
  color: #13A9E1;
  padding-top: 7px;
   font-size: 20px;
   text-decoration: none;
   font-weight: bold;
   font-family: 'Times New Roman', Times, serif;
}

.header__ribbon > a:hover{
  display: flex;
  background-color: #A2D581;
  border-radius: 5px;
  margin-bottom: 15px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  /* margin-top: 5px; */
  /* margin-bottom: 55px; */
  font-size: 22px;
  justify-content: center;
  height: 40px;
}

.imageContainer {
  display: flex;
  /* height: 460px; */
  margin-left: 70px;

}

.imageContainer > img {
  border-radius: 25px;
  border: 7px solid #13A9E1;
  height: 460px;
  width: 1520px;
}

.bottomImageContainer{
  display: flex;
  justify-content: space-evenly;

}

.bottomImageContainer > img {
  border: #13A9E1;
  height: 240px;
  border: 7px solid #A2D581;
  border-radius: 20px;

}

.aboveFooter{
  display: flex;
  justify-content: space-evenly;
  color: blue;
}

.aboveFooter > div {
  /* border: 1px solid #5acd0d; */
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}
.footer {
  display: flex;
  justify-content: space-around; /* Adjust as needed to achieve desired spacing */
  align-items: center;
}

.address,
.phone,
.email {
  padding: 10px; 
  color: #12A9E1;/* Adjust padding as needed */
}
.about {
  padding-left: 200px;
  padding-right: 200px;
  color: #07A64E;
  border: #07A64E 1px solid;
  font-size: 20px;
  background-color: rgba(7, 166, 78, 0.1);
  border-radius: 10px; /* Adjust the border-radius as needed */
  text-align: justify;
}
.contact {
  padding-left: 200px;
  padding-right: 200px;
  color: #07A64E;
  border: #07A64E 1px solid;
  font-size: 20px;
  background-color: rgba(7, 28, 166, 0.1);
  border-radius: 10px; /* Adjust the border-radius as needed */
  text-align: justify;
}